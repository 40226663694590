document.addEventListener('DOMContentLoaded', function () {
  var mySwiper = new Swiper('.swiper-container', {
    direction: "horizontal",
    slidesPerView: 3.3,
    spaceBetween: 40,
    loop: false,
    mousewheelSensitivity: 1,
    mousewheel: {
      releaseOnEdges: true,
    },
    // freeMode: {
    //   enabled: true,
    //   sticky: false,
    //   momentumBounce: false,
    // },
    breakpoints: {
      320: {
        slidesPerView: 1.1,
      },
      640: {
        slidesPerView: 2.5,
      },
      1000: {
        slidesPerView: 3.3,
      },
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    effect: 'slide', // Dodavanje efekta 'slide' za tranziciju između slajdova
    speed: 1000, // Brzina tranzicije (800 milisekundi)
  });
});


document.addEventListener("DOMContentLoaded", function() {
  // Add event listener to color-list items
  var colorItems = document.querySelectorAll('.hero-product .color-list-item');
  colorItems.forEach(function (item) {
    item.addEventListener('click', function () {
      // Remove 'active' class from all items
      colorItems.forEach(function (item) {
        item.classList.remove('active');
      });
      // Add 'active' class to the clicked item
      this.classList.add('active');

      // Hide all images and remove fadeIn class
      var images = document.querySelectorAll('.hero-product .col-lg-6 img');
      images.forEach(function (img) {
        img.classList.add('d-none'); 
        img.classList.remove('fadeInUp'); // Remove fadeIn class
      }); 
      // Show the corresponding image based on data-color-id
      var imageToShow = document.querySelector('.hero-product .col-lg-6 img[data-image-id="' + this.dataset.colorId + '"]');
      imageToShow.classList.remove('d-none');
      imageToShow.classList.add('animated', 'fadeInUp'); // Add animated and fadeIn classes

      // Get the background color from the clicked color item
      var backgroundColor = this.dataset.bgColor;

      // Apply the background color to the section with transition
      var heroSection = document.querySelector('.hero-product');
      heroSection.style.transition = "background-color 0.5s ease"; // Set transition
      heroSection.style.backgroundColor = backgroundColor;
    });
  });

  // Get background color from the initially active color
  var initialBackgroundColor = document.querySelector('.hero-product .color-list-item.active').dataset.bgColor;
  // Apply the initial background color to the section
  document.querySelector('.hero-product').style.backgroundColor = initialBackgroundColor;
});




(function ($) {
  $(function () { 


    // Pratimo skrolovanje korisnika
    window.addEventListener('scroll', function () {
      const timelineItems = document.querySelectorAll('.timeline-container ul.tl li');

      // Iteriramo kroz svaki timeline item da proverimo da li je vidljiv u viewport-u
      timelineItems.forEach((item, index) => {
        const rect = item.getBoundingClientRect();

        // Proveravamo da li je item unutar viewport-a
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          // Dodajemo klasu 'active' na vidljivi item
          item.classList.add('active');
        } else {
          // Uklanjamo klasu 'active' sa svih ostalih item-a
          item.classList.remove('active');
        }
      });
    });


    // Dodajte klasu "active" kada je slika u viewport-u
    window.addEventListener('scroll', function () {
      const img = document.getElementById('meet-img');
      const rect = img.getBoundingClientRect();

      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        // Slika je vidljiva u viewport-u, dodajemo klasu "active"
        img.classList.add('active');
      } else {
        // Slika nije vidljiva u viewport-u, uklanjamo klasu "active"
        img.classList.remove('active');
      }
    });



    const onScrollID = (_this, offsetValue) => {
      // e.preventDefault();
      // target element id
      var id = _this.attr('href');

      // target element
      var $id = $(id);
      if ($id.length === 0) {
        return;
      }

      var pos = $id.offset().top + offsetValue;

      // animated top scrolling
      $('body, html').animate({
        scrollTop: pos
      });
    }

    $(document).on('click', 'a.navbar-brand, .navbar a[href^="#"], .scroll-down', function (e) {
      const _this = $(this)
      onScrollID(_this, -50);
    });


    function updateActiveNavigationLink() {
      const navigationLinks = document.querySelectorAll('#primary-menu a');
      const scrollPosition = window.scrollY;

      navigationLinks.forEach(link => {
        const href = link.getAttribute('href');

        if (href.startsWith('#')) { // Preskačemo linkove koji ne vode na sekcije unutar iste stranice
          const sectionId = href;
          const section = document.querySelector(sectionId);

          if (section) {
            const sectionOffsetTop = section.offsetTop - 200;
            const sectionHeight = section.offsetHeight;

            const listItem = link.closest('li'); // Pronalazi najbliži roditeljski li element

            if (scrollPosition >= sectionOffsetTop && scrollPosition < sectionOffsetTop + sectionHeight) {
              listItem.classList.add('active'); // Dodaje active klasu na roditeljski li element
            } else {
              listItem.classList.remove('active'); // Uklanja active klasu sa roditeljskog li elementa
            }
          }
        }
      });
    }

    // Dodavanje događaja za skrol
    window.addEventListener('scroll', updateActiveNavigationLink);

    AOS.init();


    $('.nav-button').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      $('.navigation-floating').toggleClass('opened');
      $('.overlay-black').addClass('active');

    });

    $('.nav-button.active').on('click', function () {
      $(this).removeClass('active');
      $('.navigation-floating.opened').removeClass('opened');
    });

    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top fadeInDown animated');
      } else {
        $('.site-header').removeClass('navbar-fixed-top fadeInDown animated');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Menü'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

  });
})(jQuery);